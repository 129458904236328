import React from "react";
import {useStaticQuery, graphql} from "exo";
const Members = () => {
  const data = useStaticQuery(graphql`
    query memberExperience {
      allExoMemberExperience {
        edges {
          node {
            sections {
              mention
              titleCaption
              description
              designation
              logoImage {
                alt
                asset {
                  url
                }
              }
              mainImage {
                alt
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  
  return (
    <div className="w-full bg-white">
      <div className="md:max-w-5xl mx-auto px-8 py-8 md:py-14">
        <p className="text-black text-lg font-normal pt-10 pb-16">
          Auto Genius members are at the center of everything we do. Our goal is to help
          every leader unlock and achieve their potential through mentoring, discussion, experimentation, and world-class content. 
          <br/><br/>
          <strong className="font-bold">Our mission:</strong> To provide automotive marketers with
          the knowledge, technology, services, network, and community required to grow, stay challenged, and foster innovation
          in today's competitive market.
        </p>
      </div>
      {/* Second Section */}
      <div className="w-full bg-bodyColor">
        <div className="max-w-7xl mx-auto px-10 md:px-8 pt-28 md:pb-28">
          <div className="flex flex-col md:flex-row justify-center md:justify-between">
            <div className="relative md:max-w-xl mb-10 md:mb-0">
              {/* <div className="diagonal-top"></div>
              <div className="diagonal-bottom"></div> */}
              <div className="memeber-clipcard new-shadow bg-white px-5 md:px-16 py-6 md:mr-10 md:py-10">
                <h1 className="text-black text-3xl md:text-4xl pb-5">
                  {data.allExoMemberExperience.edges[0].node.sections[1]
                    .mention !== undefined
                    ? data.allExoMemberExperience.edges[0].node.sections[1]
                        .mention
                    : null}
                </h1>
                <p className="text-black font-normal text-md pb-2">
                  {data.allExoMemberExperience.edges[0].node.sections[1]
                    .titleCaption !== undefined
                    ? data.allExoMemberExperience.edges[0].node.sections[1]
                        .titleCaption
                    : null}
                </p>
              </div>
            </div>
            <div className="md:max-w-xl">
              <p className="text-black text-md italic pb-14 md:pb-8 md:px-16">
                {data.allExoMemberExperience.edges[0].node.sections[1]
                  .description !== undefined
                  ? data.allExoMemberExperience.edges[0].node.sections[1]
                      .description
                  : null}
              </p>
              <div className="flex flex-row md:px-16">
                <div>
                  <img
                    src={
                      data.allExoMemberExperience.edges[0].node.sections[1]
                        .mainImage.asset.url !== undefined
                        ? JSON.parse(JSON.stringify(data.allExoMemberExperience.edges[0].node
                            .sections[1].mainImage.asset.url).replace(/https:\/\/cdn.sanity.io/g,"https://www.autogenius.io/auto/genius/marketing"))
                        : null
                    }
                    alt={
                      data.allExoMemberExperience.edges[0].node.sections[1]
                        .mainImage.alt
                    }
                    className="w-24 shadow-xl rounded-full"
                  />
                </div>
                <div className="pl-5">
                  <img
                    src={
                      data.allExoMemberExperience.edges[0].node.sections[1]
                        .logoImage.asset.url !== undefined
                        ? JSON.parse(JSON.stringify(data.allExoMemberExperience.edges[0].node
                            .sections[1].logoImage.asset.url).replace(/https:\/\/cdn.sanity.io/g,"https://www.autogenius.io/auto/genius/marketing"))
                        : null
                    }
                    alt={
                      data.allExoMemberExperience.edges[0].node.sections[1]
                        .logoImage.alt
                    }
                    className="mb-5 mt-3 w-24"
                  />
                  <p className="text-black text-sm font-normal w-52">
                    {data.allExoMemberExperience.edges[0].node.sections[1]
                      .designation !== undefined
                      ? data.allExoMemberExperience.edges[0].node.sections[1]
                          .designation
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      {/* 4th Section */}
      <div className="w-full bg-bodyColor">
        <div className="max-w-7xl mx-auto px-10 md:px-8 py-28">
          <div className="flex flex-col md:flex-row justify-center md:justify-between">
            <div className="relative md:max-w-xl mb-10 md:mb-0">
              {/* <div className="diagonal-top"></div>
              <div className="diagonal-bottom"></div> */}
              <div className="memeber-clipcard new-shadow bg-white px-5 md:px-16 py-6 md:mr-10 md:py-10">
                <h1 className="text-black text-3xl md:text-4xl pb-5">
                  {data.allExoMemberExperience.edges[0].node.sections[3]
                    .mention !== undefined
                    ? data.allExoMemberExperience.edges[0].node.sections[3]
                        .mention
                    : null}
                </h1>
                <p className="text-black font-normal text-md pb-2">
                  Ask for peers to review your work, find bugs, stress test results, and get constructive feedback.
                </p>
              </div>
            </div>
            <div className="md:max-w-xl">
              <p className="text-black text-md italic pb-14 md:pb-8 md:px-16">
                {data.allExoMemberExperience.edges[0].node.sections[3]
                  .titleCaption !== undefined
                  ? data.allExoMemberExperience.edges[0].node.sections[3]
                      .titleCaption
                  : null}
              </p>
              <div className="flex flex-row md:px-16">
                <div>
                  <img
                    src={
                      data.allExoMemberExperience.edges[0].node.sections[3]
                        .mainImage.asset.url !== undefined
                        ? JSON.parse(JSON.stringify(data.allExoMemberExperience.edges[0].node
                            .sections[3].mainImage.asset.url).replace(/https:\/\/cdn.sanity.io/g,"https://www.autogenius.io/auto/genius/marketing"))
                        : null
                    }
                    alt={
                      data.allExoMemberExperience.edges[0].node.sections[3]
                        .mainImage.alt
                    }
                    className="w-24 shadow-xl rounded-full"
                  />
                </div>
                <div className="pl-5">
                  <img
                    src={
                      data.allExoMemberExperience.edges[0].node.sections[3]
                        .logoImage.asset.url !== undefined
                        ? JSON.parse(JSON.stringify(data.allExoMemberExperience.edges[0].node
                            .sections[3].logoImage.asset.url).replace(/https:\/\/cdn.sanity.io/g,"https://www.autogenius.io/auto/genius/marketing"))
                        : null
                    }
                    alt={
                      data.allExoMemberExperience.edges[0].node.sections[3]
                        .logoImage.alt
                    }
                    className="mb-5 mt-3 w-24"
                  />
                  <p className="text-black text-sm font-normal w-52">
                    {data.allExoMemberExperience.edges[0].node.sections[3]
                      .designation !== undefined
                      ? data.allExoMemberExperience.edges[0].node.sections[3]
                          .designation
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* 6th section */}
      <div className="w-full bg-bodyColor">
        <div className="max-w-7xl mx-auto px-10 md:px-8 pb-28 md:pt-28">
          <div className="flex flex-col md:flex-row justify-center md:justify-between">
            <div className="relative md:max-w-xl mb-10 md:mb-0">
              {/* <div className="diagonal-top"></div>
              <div className="diagonal-bottom"></div> */}
              <div className="memeber-clipcard px-5 new-shadow bg-white md:px-16 py-6 md:mr-10 md:py-10">
                <h1 className="text-black text-3xl md:text-4xl pb-5">
                  {data.allExoMemberExperience.edges[0].node.sections[5]
                    .mention !== undefined
                    ? data.allExoMemberExperience.edges[0].node.sections[5]
                        .mention
                    : null}
                </h1>
                <p className="text-black font-normal text-md pb-2">
                  {data.allExoMemberExperience.edges[0].node.sections[5]
                    .titleCaption !== undefined
                    ? data.allExoMemberExperience.edges[0].node.sections[5]
                        .titleCaption
                    : null}
                </p>
              </div>
            </div>
            <div className="md:max-w-xl">
              <p className="text-black text-md italic pb-14 md:pb-8 md:px-16">
                {data.allExoMemberExperience.edges[0].node.sections[5]
                  .description !== undefined
                  ? data.allExoMemberExperience.edges[0].node.sections[5]
                      .description
                  : null}
              </p>
              <div className="flex flex-row md:px-16">
                <div>
                  <img
                    src={
                      data.allExoMemberExperience.edges[0].node.sections[5]
                        .mainImage.asset.url !== undefined
                        ? JSON.parse(JSON.stringify(data.allExoMemberExperience.edges[0].node
                            .sections[5].mainImage.asset.url).replace(/https:\/\/cdn.sanity.io/g,"https://www.autogenius.io/auto/genius/marketing"))
                        : null
                    }
                    alt={
                      data.allExoMemberExperience.edges[0].node.sections[5]
                        .mainImage.alt
                    }
                    className="w-24 shadow-xl rounded-full"
                  />
                </div>
                <div className="pl-5">
                  <img
                    src={
                      data.allExoMemberExperience.edges[0].node.sections[5]
                        .logoImage.asset.url !== undefined
                        ? JSON.parse(JSON.stringify(data.allExoMemberExperience.edges[0].node
                            .sections[5].logoImage.asset.url).replace(/https:\/\/cdn.sanity.io/g,"https://www.autogenius.io/auto/genius/marketing"))
                        : null
                    }
                    alt={
                      data.allExoMemberExperience.edges[0].node.sections[5]
                        .logoImage.alt
                    }
                    className="mb-5 mt-3 w-24"
                  />
                  <p className="text-black text-sm font-normal w-52">
                    {data.allExoMemberExperience.edges[0].node.sections[5]
                      .designation !== undefined
                      ? data.allExoMemberExperience.edges[0].node.sections[5]
                          .designation
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Members;
